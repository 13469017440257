import { useDispatch, useSelector } from "react-redux";
import BackButton from "../components/BackButton";
import { getTicket, startOrCloseTicket } from "../features/tickets/ticketSlice";
import {
  getNotes,
  createNote,
  reset as notesReset,
} from "../features/notes/noteSlice";
import Spinner from "../components/Spinner";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NoteItem from "../components/NoteItem";
import Modal from "react-modal";
import { FaPlus, FaRegCalendar } from "react-icons/fa";
import { format } from "date-fns";
import { capitalizeFirstLetter } from "../helpers/utils";

const customStyles = {
  content: {
    width: "600px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    position: "relative",
  },
};

Modal.setAppElement("#root");

function Ticket() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [textAreaFocused, setIsTextareaFocused] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState({});

  const { user } = useSelector((state) => state.auth);

  const { ticket, isLoading, isError, message } = useSelector(
    (state) => state.tickets
  );

  const { notes, isLoading: notesIsLoading } = useSelector(
    (state) => state.notes
  );

  const { ticketId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    dispatch(getTicket(ticketId));
    dispatch(getNotes(ticketId));
  }, [isError, message, ticketId]);

  if (isLoading || notesIsLoading) return <Spinner />;

  if (isError) {
    return <h3>Something went wrong</h3>;
  }

  // Start/Close ticket
  const onTicketStartOrClose = (status) => {
    const payload = {
      ticketId: ticketId,
      status: status,
    };
    dispatch(startOrCloseTicket(payload)).then(() => {
      dispatch(getTicket(ticketId));
      dispatch(getNotes(ticketId));
      setIsButtonDisabled({});
    });
    if (status.includes("close")) {
      setIsButtonDisabled((prev) => ({ ...prev, close: true }));
    } else {
      setIsButtonDisabled((prev) => ({ ...prev, start: true }));
    }
    toast.success(
      `${status.includes("close") ? "Ticket Closed" : "Ticket Started"}`
    );
  };

  // Open/Close Modal
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Create Note Submit
  const onNoteSubmit = (e) => {
    e.preventDefault();
    if (noteText.length) {
      dispatch(createNote({ ticketId, noteText }));
      closeModal();
      setIsTextareaFocused(false);
      setNoteText("");
      dispatch(getNotes(ticketId));
    }
  };
  const getSubmittedDate = () => {
    const parsedTimestamp = new Date(ticket?.createdAt);

    // Check if the parsedTimestamp is valid
    if (isNaN(parsedTimestamp.getTime())) {
      return "Invalid Date";
    }

    return format(parsedTimestamp, "MMM dd, yyyy - h:mm a");
  };

  const getBadgeColor = () => {
    if (ticket?.status?.toLowerCase() === "open") {
      return "bg-customGreen";
    } else if (ticket?.status?.toLowerCase() === "in-progress") {
      return "bg-primary";
    } else if (ticket?.status?.toLowerCase() === "close") {
      return "bg-customRed";
    }
  };

  return (
    <div className="ticket-page">
      <header className="ticket-header">
        <div className="mb-7 pb-7 border-b border-gray-300">
          <div className="flex justify-between">
            <div className="flex items-center">
              <h2 className=" text-xl">Ticket ID: #{ticket?.ticketNo}</h2>
              <div className="ml-7 flex items-center mb-2.5">
                <div className="flex items-center">
                  {ticket?.isOnPriority ? (
                    <>
                      <div className="w-3 h-3 bg-[#ee240e] rounded-full mr-1"></div>
                      <span>High</span>
                    </>
                  ) : null}
                </div>
                <div className="flex items-center ml-2">
                  <div
                    className={`w-3 h-3 ${getBadgeColor()} rounded-full mr-1`}
                  ></div>
                  <span>{capitalizeFirstLetter(ticket?.status)}</span>
                </div>
              </div>
            </div>
            <div className="flex">
              {user?.role === "admin" && ticket?.status === "open" ? (
                <button
                  className={`btn text-white  ${
                    isButtonDisabled?.start ? "bg-gray-400" : "bg-primary"
                  }`}
                  style={{ marginBottom: "4px" }}
                  onClick={() => onTicketStartOrClose("in-progress")}
                  disabled={isButtonDisabled?.start}
                >
                  Start ticket
                </button>
              ) : null}
              {ticket?.status !== "close" && (
                <button
                  onClick={() => onTicketStartOrClose("close")}
                  className={`btn ml-3 text-white ${
                    isButtonDisabled?.close ? "bg-gray-400" : "bg-customRed"
                  }`}
                  style={{ marginBottom: "4px" }}
                  disabled={isButtonDisabled?.close}
                >
                  Close Ticket
                </button>
              )}
            </div>
          </div>
          <p className="flex items-center text-base text-grey">
            <FaRegCalendar className="mr-2 text-primary" />{" "}
            <span>Created on: {getSubmittedDate()}</span>
          </p>
          {ticket?.dueDate ? (
            <p className="flex items-center text-base text-grey">
              <FaRegCalendar className="mr-2 text-primary" />{" "}
              <span>Due date: {format(ticket?.dueDate, "MMM dd, yyyy")}</span>
            </p>
          ) : null}
        </div>
        <h4>Project: {ticket?.project}</h4>
        <p className="text-sm text-grey mb-5">
          Ticket created by: {ticket?.name}
        </p>

        <div className="pb-6 mb-7 border-b border-gray-300">
          <h3 className="text-base">Description of Issue</h3>
          <p className="text-base text-grey">{ticket?.description}</p>
        </div>
        <h3 className="text-lg">Notes</h3>
      </header>
      {ticket?.status !== "close" && (
        <div className="mb-5">
          <form onSubmit={onNoteSubmit}>
            <input
              className="border rounded-md border-gray-300 w-full p-3 focus:outline-none"
              placeholder="Add a comment..."
              value={noteText}
              onChange={(e) => setNoteText(e.target.value)}
              onFocus={() => setIsTextareaFocused(true)}
            />
            {textAreaFocused || noteText?.length ? (
              <div className="flex mt-2">
                <button
                  className="btn bg-primary text-white mr-2 w-28"
                  type="submit"
                  style={{ marginBottom: 0 }}
                >
                  Save
                </button>
                <button
                  className="btn border border-[#d0d5dd] text-primary w-28"
                  style={{ marginBottom: 0 }}
                  type="submit"
                  onClick={() => {
                    setIsTextareaFocused(false);
                    setNoteText("");
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : null}
          </form>
        </div>
      )}

      {notes?.length
        ? notes?.map((note) => <NoteItem key={note?._id} note={note} />)
        : null}
    </div>
  );
}

export default Ticket;
