import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { login, reset } from "../features/auth/authSlice";
import "./index.css";
import { Box, Card, Container } from "@mui/material";
import logo from "../assets/dmllogo.svg";
import loginImg from "../assets/persononcall.png";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";

function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
   * useSelector() allows you to extract data from the Redux store state,
   * using a selector function.
   */

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      )
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    // Redirect when logged in
    if (isSuccess || user) {
      navigate("/tickets");
    }

    dispatch(reset());
  }, [isError, isSuccess, user, navigate, message, dispatch]);

  return (
    <div className="h-screen flex">
      <div className="w-1/2 h-full">
        <img src={loginImg} alt="img" className="w-full h-full object-cover" />
      </div>
      <div className="flex text-center w-1/2">
        <Container
          component="main"
          maxWidth="xs"
          sx={{ marginTop: "auto", marginBottom: "auto" }}
        >
          <div className="flex items-center justify-center mb-10">
            <img className={`w-[2.5rem] h-[2.5rem]`} src={logo} alt="logo" />
            <span className="header-text">evOps Support</span>
          </div>
          <Card variant="outlined" className="auth-card">
            <section className="heading">
              <h1 className="text-xl">Login</h1>
              <p className="text-base text-grey">
                Please log in to get support
              </p>
            </section>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                const userData = {
                  email: values.email,
                  password: values.password,
                };
                dispatch(login(userData));
                setSubmitting(false);
              }}
            >
              {({
                isValid,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
              }) => (
                <Form>
                  <Box sx={{ mt: 3 }}>
                    <div className="form-group mb-1">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        className={`form-control ${
                          errors.email && touched.email ? "!border-red-500" : ""
                        }`}
                        maxLength="50"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500 pl-0.5 mt-[-10px] !text-xs text-left"
                      />
                    </div>
                    <div className="form-group relative mb-2">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Enter your password"
                        className={`form-control ${
                          errors.password && touched.password
                            ? "!border-red-500"
                            : ""
                        }`}
                        maxLength="30"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                      />
                      <span
                        type="span"
                        className="absolute top-[0.68rem] right-2 focus:outline-none cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <FaEye className="h-5 w-5 text-gray-400" />
                        ) : (
                          <FaEyeSlash className="h-5 w-5 text-gray-400" />
                        )}
                      </span>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500 pl-0.5 mt-[-10px] !text-xs text-left"
                      />
                    </div>
                    <div className="form-group">
                      <button
                        className={`btn btn-block ${
                          !isValid || !dirty || isSubmitting
                            ? "bg-gray-400 cursor-not-allowed"
                            : "bg-primary hover:bg-primary-dark"
                        } text-white`}
                        type="submit"
                        disabled={
                          isSubmitting || isLoading || !isValid || !dirty
                        }
                      >
                        {isSubmitting || isLoading ? "Loading..." : "Continue"}
                      </button>
                    </div>
                    <p>
                      Don't have an account?{" "}
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => navigate("/register")}
                      >
                        Sign Up
                      </span>
                    </p>
                  </Box>
                </Form>
              )}
            </Formik>
          </Card>
        </Container>
      </div>
    </div>
  );
}

export default Login;
