import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import { PrivateRoute } from "./routes/PrivateRoute";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NewTicket from "./pages/NewTicket";
import Register from "./pages/Register";
import Tickets from "./pages/Tickets";
import Ticket from "./pages/Ticket";
import UserProfile from "./pages/UserProfile";
import { useAuthStatus } from "./hooks/useAuthStatus";
import Settings from "./pages/Settings";

function App() {
  const { loggedIn } = useAuthStatus();

  return (
    <>
      <Router>
        <div>
          {loggedIn && <Header />}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/user-profile" element={<UserProfile />} />
              <Route path="/tickets" element={<Tickets />} />
              <Route path="/ticket/:ticketId" element={<Ticket />} />
              <Route path="/new-ticket" element={<NewTicket />} />
              <Route path="/settings" element={<Settings />} />
            </Route>
            <Route path="*" element={<Navigate to="/tickets" />} />
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
