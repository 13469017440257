import { format } from "date-fns";
import { useSelector } from "react-redux";

function NoteItem({ note }) {
  const { user } = useSelector((state) => state.auth);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return (
    <div
      className="note mb-2"
      style={{
        backgroundColor: note?.role !== "admin" ? "rgba(0,0,0,0.7)" : "#fff",
        color: note?.role !== "admin" ? "#fff" : "#000",
      }}
    >
      <h4>
        Note from {user.name}
        {note?.role === "admin" ? (
          <span className="primary-text">(Admin)</span>
        ) : (
          ""
        )}
      </h4>
      <p>{note?.text}</p>
      <div className="note-date">
        {format(new Date(note?.createdAt), "MMM dd, yyyy h:mm a")}
      </div>
    </div>
  );
}

export default NoteItem;
