import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthStatus } from "../hooks/useAuthStatus";
import Spinner from "../components/Spinner";

export const PrivateRoute = () => {
  const { loggedIn, checkingStatus } = useAuthStatus();

  const location = useLocation();

  if (location.pathname === "/" && loggedIn) return <Navigate to="/tickets" />;

  if (checkingStatus) {
    return <Spinner />;
  }

  return loggedIn ? (
    <div className="container">
      <Outlet />
    </div>
  ) : (
    <Navigate to="/login" />
  );

  return <div></div>;
};
