import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import departmentService from "./departmentService";
import managerService from "../manager/managerService";
import { toast } from "react-toastify";

const initialState = {
  departments: [],
  department: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getAllDepartments = createAsyncThunk(
  "departments/getAll",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token || '';

      return await departmentService.getAllDepartments(token);
    } catch (err) {
      const message =
        err?.response?.data?.message || err?.message || err?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getDepartment = createAsyncThunk(
  "departments/get",
  async (departmentId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;

      return await departmentService.getDepartment(departmentId, token);
    } catch (err) {
      const message =
        err?.response?.data?.message || err?.message || err?.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteDepartment = createAsyncThunk(
  "departments/delete",
  async (departmentId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;

      return await departmentService.deleteDepartment(departmentId, token);
    } catch (err) {
      const message =
        err?.response?.data?.message || err?.message || err?.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateDepartment = createAsyncThunk(
  "departments/update",
  async (departmentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;

      return await departmentService.updateDepartment(departmentData, token);
    } catch (err) {
      const message =
        err?.response?.data?.message || err?.message || err?.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createDepartment = createAsyncThunk(
  "departments/create",
  async (departmentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;

      return await departmentService.createDepartment(departmentData, token);
    } catch (err) {
      const message =
        err?.response?.data?.message || err?.message || err?.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const departmentSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDepartments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDepartments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.departments = action.payload;
      })
      .addCase(getAllDepartments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.departments.push(action.payload);
      })
      .addCase(createDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(deleteDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDepartment.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(deleteDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(getDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.department = action.payload;
      })
      .addCase(getDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.department = action.payload;
      })
      .addCase(updateDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export default departmentSlice.reducer;
