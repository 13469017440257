import {
  FaSignOutAlt,
  FaSignInAlt,
  FaUser,
  FaUserEdit,
  FaRegUser,
  FaRegSun,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./index.css";
import { logout, reset as resetAuth } from "../features/auth/authSlice";

import logo from "../assets/dmllogo.svg";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useState } from "react";
import { reset as resetTicket } from "../features/tickets/ticketSlice";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [profileClick, setProfileClick] = useState(null);
  const open = Boolean(profileClick);

  const onLogout = () => {
    dispatch(logout());
    dispatch(resetAuth());
    dispatch(resetTicket());
    navigate("/");
    setProfileClick(null);
  };

  const handleProfileClick = (event) => {
    setProfileClick(event.currentTarget);
  };

  return (
    <header
      className="container"
      style={{ marginBottom: "0", paddingBottom: "0" }}
    >
      <div className="header h-16 flex mb-7 items-center py-5 justify-between">
        <div className="logo">
          <Link to="/">
            <div className="flex items-center">
              <img className="h-8 w-6 mr-1" src={logo} alt="" />
              <span className="header-text">evOps Support</span>
            </div>
          </Link>
        </div>
        <div className="flex items-center">
          {user?.role === "admin" ? (
            <FaRegSun
              className="text-xl mr-8 cursor-pointer"
              onClick={() => navigate("/settings")}
            />
          ) : null}
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={handleProfileClick}
          >
            <Avatar sx={{ bgcolor: blue[700] }}>
              {user?.name?.split(" ")[0][0]?.toUpperCase()}
            </Avatar>
            <div className="font-medium text-left">
              <div>{user?.name}</div>
              <div className="text-sm text-gray-500">
                {user?.role === "admin" ? "admin" : ""}
              </div>
            </div>
          </div>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={profileClick}
          open={open}
          onClose={() => setProfileClick(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              navigate("/user-profile");
              setProfileClick(null);
            }}
          >
            <FaRegUser className="text-primary" />{" "}
            <span className="px-2">My Profile</span>
          </MenuItem>
          <MenuItem onClick={onLogout}>
            <FaSignOutAlt className="text-primary" />
            <span className="px-2">Logout</span>
          </MenuItem>
        </Menu>
      </div>
    </header>
  );
}

export default Header;
